import React, { useState } from 'react';

const PhotoGallery = () => {
  // State to manage selected photo category
  const [selectedCategory, setSelectedCategory] = useState('Realistic');

  // Dummy images and categories
  const photoCategories = [
    
    {
      name: 'Realistic',
      images: [
        "./images/generated-img/1.webp",
        "./images/generated-img/5.webp",
        "./images/generated-img/2.webp",
      ],
    },
    {
      name: 'Anime',
      images: [
        "./images/generated-img/17.webp",
        "./images/generated-img/18.webp",
        "./images/generated-img/6.webp",
      ],
    },
    {
      name: 'Artistic',
      images: [
        "./images/generated-img/22.webp",
        "./images/generated-img/10.webp",
        "./images/generated-img/25.webp",
      ],
    },
    {
      name: '3D',
      images: [
        "./images/generated-img/7.webp",
        "./images/generated-img/26.webp",
        "./images/generated-img/15.webp",
      ],
    },
    {
      name: 'Fantasy',
      images: [
        "./images/generated-img/24.webp",
        "./images/generated-img/23.webp",
        "./images/generated-img/21.webp",
      ],
    }, 
  ];

  return (
    <section className="bg-black py-16 md:pt-0 md:pb-28 px-4">
      <div className="max-w-7xl mx-auto text-center">
        {/* Main Heading */}
        <h2 className="text-4xl font-extrabold text-white">Explore <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">AI-Generated </span> Photo Styles</h2>

        {/* Description */}
        <p className="text-gray-400 mt-4 text-lg max-w-3xl mx-auto">
          Discover a wide variety of  AI-generated  images across different styles. Whether you're fascinated by Anime, enchanted by 3D visuals, or mesmerized by realistic photography, we have it all. Select a category below and explore the creativity.
        </p>

        {/* Category Buttons */}
        <div className="flex justify-center mt-8 space-x-4 flex-wrap">
          {photoCategories.map((category) => (
            <button
              key={category.name}
              className={`px-4 w-20 py-2 mb-4 rounded-full text-sm font-semibold ${
                selectedCategory === category.name
                  ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                  : 'bg-gray-800 text-gray-400'
              } hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-600 transition`}
              onClick={() => setSelectedCategory(category.name)}
            >
              {category.name}
            </button>
          ))}
        </div>

        {/* Photo Style Images */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {photoCategories
            .filter((category) => category.name === selectedCategory)
            .flatMap((category) =>
              category.images.map((image, index) => (
                <div key={index} className="rounded-lg shadow-lg overflow-hidden">
                  <img
                    src={image}
                    alt={`${category.name} ${index + 1}`}
                    className="w-full h-68 object-cover transition-transform transform hover:scale-105"
                  />
                </div>
              ))
            )}
        </div>

        {/* Call to Action Button */}
        <div className="mt-12">
          <a href="/model-box" className="px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full shadow-md hover:from-blue-600 hover:to-purple-600 transition">
            Generate Your AI Image Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default PhotoGallery;
