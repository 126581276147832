import React, { useState } from 'react';

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('AI Realistic Image');

  const categories = [
    'AI Realistic Image',
    'AI Anime Image',
    'AI Artistic Image',
    'AI 3D Image',
  ];

  const faqs = {
    'AI Realistic Image': [
      {
        question: 'How does the AI generate realistic images?',
        answer:
          'Our AI uses advanced machine learning models trained on real-world images to understand textures, lighting, and depth, creating highly realistic visuals based on text descriptions.',
      },
      {
        question: 'Can I customize the level of realism in the generated images?',
        answer:
          'Yes, you can adjust the realism by modifying settings such as lighting, contrast, and color balance. You can also provide more detailed descriptions to influence the output.',
      },
      {
        question: 'What kind of objects and environments can I generate?',
        answer:
          'You can generate realistic images of objects, landscapes, interiors, and even specific scenes like a sunset beach or a cityscape. The AI excels at replicating real-world features.',
      },
      {
        question: 'Can the AI create realistic human portraits?',
        answer:
          'Yes, our AI can generate human portraits that look lifelike, down to the smallest details like skin texture and facial expressions. It can also create portraits in different poses and lighting conditions.',
      },
    ],
    'AI Anime Image': [
      {
        question: 'How does the AI generate anime-style images?',
        answer:
          'Our AI is trained on a variety of anime datasets, allowing it to understand the unique characteristics of anime, including big expressive eyes, stylized hair, and exaggerated emotions.',
      },
      {
        question: 'Can I choose specific anime styles (e.g., Shonen, Shojo)?',
        answer:
          'Yes, by specifying the style or genre (e.g., Shonen, Shojo, or Mecha) in your text prompt, the AI will adjust its output to match the requested style.',
      },
      {
        question: 'Is it possible to create custom anime characters?',
        answer:
          'Absolutely! You can describe the appearance, outfit, and personality of your anime character in the prompt, and the AI will generate an original character based on your input.',
      },
      {
        question: 'Can the AI generate anime scenes or backgrounds?',
        answer:
          'Yes, the AI can generate not only characters but also full scenes or backgrounds, such as anime cityscapes, fantasy worlds, or action-packed battle scenes.',
      },
    ],
    'AI Artistic Image': [
      {
        question: 'What kind of art styles can the AI generate?',
        answer:
          'The AI can generate images in a wide variety of artistic styles, including abstract, impressionist, modern, and even cubism. You can guide the AI by describing the desired style in the prompt.',
      },
      {
        question: 'Can I generate artistic images based on famous artists’ styles?',
        answer:
          'Yes, the AI can emulate the styles of famous artists like Van Gogh, Picasso, or Monet. By mentioning the artist’s name in your prompt, the AI will adjust its output to resemble their iconic style.',
      },
      {
        question: 'Can the AI create abstract or conceptual art?',
        answer:
          'Yes, the AI excels at creating abstract and conceptual art based on broad or poetic descriptions. You can experiment with words to guide the AI in producing truly unique and artistic visuals.',
      },
      {
        question: 'How customizable are the generated artistic images?',
        answer:
          'You can adjust various parameters, such as color schemes, brush stroke effects, and textures, to achieve the exact artistic vision you’re aiming for. The AI adapts to your creative input.',
      },
    ],
    'AI 3D Image': [
      {
        question: 'How does the AI generate 3D images?',
        answer:
          'Our AI can generate 3D-like images by simulating depth, shadows, and lighting, making objects appear three-dimensional. This is perfect for creating models or visual concepts.',
      },
      {
        question: 'Can I generate 3D characters or objects?',
        answer:
          'Yes, you can describe any 3D object or character in your prompt, and the AI will generate a 3D-like image of it, complete with details like shadows, textures, and lighting to give it depth.',
      },
      {
        question: 'Is there a way to control the camera angle in the generated 3D images?',
        answer:
          'You can specify the viewing angle or perspective (e.g., top-down, side view, or close-up) in your text prompt, and the AI will adjust the scene accordingly.',
      },
      {
        question: 'Can I use the AI-generated 3D images for 3D printing or modeling?',
        answer:
          'While the AI generates 3D-like images, they are not fully-fledged 3D models. However, you can use the visuals as references for 3D modeling or concept designs for printing.',
      },
    ],
  };

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <section className="bg-black pt-16 md:pb-28 px-4">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-12">
        {/* Sidebar */}
        <div className="lg:w-1/4 text-white">
          <h4 className="text-gray-400 mb-6 uppercase tracking-widest">Categories</h4>
          <ul className="space-y-4">
            {categories.map((category, index) => (
              <li
                key={index}
                className={`pl-4 border-l-4 cursor-pointer ${
                  category === selectedCategory ? 'border-purple-500 text-white' : 'border-transparent text-gray-500'
                } hover:border-gradient-to-r hover:from-blue-500 hover:to-purple-500 hover:text-white transition`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        {/* FAQ Section */}
        <div className="lg:w-3/4">
          <div className="text-left">
            <h4 className="text-blue-500 text-sm uppercase tracking-widest mb-2">
              Creative Approach
            </h4>
            <h2 className="text-4xl font-bold text-white mb-8">
            Got Questions?  <br /> We've Got You Covered.
            </h2>
          </div>

          {/* Filtered FAQ Content */}
          <div className="space-y-6">
            {faqs[selectedCategory].map((faq, index) => (
              <div key={index}>
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full text-left py-4 px-6 bg-gray-800 rounded-lg ${
                    activeIndex === index ? 'text-blue-500' : 'text-white'
                  } hover:bg-gray-700 transition`}
                >
                  {faq.question}
                </button>
                {activeIndex === index && (
                  <div className="mt-4 pl-6 text-gray-400">
                    <p className="whitespace-pre-line">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
