import React from 'react';
import HeroSection from './Home/HomePageHero';
import FAQSection from './Home/FAQSection';
import ArtStyleGallery from './Home/ArtStyleGallery';
import TestimonialSection from './Home/Testimonialsection';
import Whatwedo from './Home/Whatwedo';

const Home = () => {
    return (
        <div> 
            <HeroSection />
            <Whatwedo />
            <ArtStyleGallery />
            <TestimonialSection />
            <FAQSection />
        </div>
    );
};

export default Home;
