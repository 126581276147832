import React, { useState, useEffect, useRef } from 'react';
import { FaExpandAlt, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

// Replace these with actual paths to your images
const images = [
  '/images/generated-img/20.webp',
  '/images/generated-img/16.webp',
  '/images/generated-img/12.webp',
  '/images/generated-img/24.webp',
  '/images/generated-img/22.webp',
  '/images/generated-img/23.webp',
  '/images/generated-img/17.webp',
  '/images/generated-img/15.webp',
  '/images/generated-img/18.webp',
  '/images/generated-img/1.webp',
  '/images/generated-img/2.webp',
  '/images/generated-img/3.webp',
  '/images/generated-img/4.webp',
  '/images/generated-img/5.webp',
  '/images/generated-img/6.webp',
  '/images/generated-img/7.webp',
  '/images/generated-img/8.webp',
  '/images/generated-img/9.webp',
];

// Animation variants for grid items
const gridItemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.6,
      type: 'spring',
      stiffness: 100,
    },
  }),
};

// Animation variants for modal
const modalVariants = {
  hidden: { opacity: 0, scale: 0.75 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, scale: 0.75, transition: { duration: 0.3 } },
};

const PortfolioSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null);

  // Close modal on ESC key press
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') closeModal();
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  // Trap focus within modal when it's open
  useEffect(() => {
    if (selectedImage) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTab = (e) => {
        if (e.key !== 'Tab') return;

        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      };

      document.addEventListener('keydown', handleTab);
      firstElement.focus();

      return () => {
        document.removeEventListener('keydown', handleTab);
      };
    }
  }, [selectedImage]);

  // Open Modal with Selected Image
  const openModal = (image) => {
    setSelectedImage(image);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  // Close Modal
  const closeModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'auto'; // Restore scrolling
  };

  return (
    <section className="pt-16 md:pb-32 bg-black">
      <div className="max-w-7xl mx-auto px-6">
        {/* Heading */}
        <div className="text-center mb-16">
          <motion.h2
            className="text-4xl font-extrabold text-white"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Stunning AI-Generated Visuals
          </motion.h2>
          <motion.p
            className="text-gray-400 mt-4 text-lg max-w-2xl mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.6 }}
          >
            Explore how AI transforms your ideas into breathtaking imagery.
          </motion.p>
        </div>

        {/* Portfolio Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="group relative cursor-pointer"
              custom={index}
              initial="hidden"
              animate="visible"
              variants={gridItemVariants}
              whileHover={{ scale: 1.05 }}
              onClick={() => openModal(image)}
            >
              {/* Image Card */}
              <div className="relative shadow-lg rounded-lg overflow-hidden bg-gradient-to-br from-blue-600 to-purple-600 p-1">
                <img
                  src={image}
                  alt={`AI Generated Image ${index + 1}`}
                  className="w-full h-64 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                />
                {/* Overlay */}
                <motion.div
                  className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  whileHover={{ opacity: 1 }}
                >
                  <FaExpandAlt className="text-white text-4xl animate-pulse" />
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Modal for Enlarged Image */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              aria-modal="true"
              role="dialog"
              onClick={closeModal}
            >
              <motion.div
                className="max-h-screen max-w-screen-lg relative"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
                ref={modalRef}
              >
                <button
                  className="absolute top-4 right-4 bg-gray-800 hover:bg-gray-600 text-white p-2 h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={closeModal}
                  aria-label="Close Modal"
                >
                  <FaTimes className="text-xl" />
                </button>
                <div className="relative bg-gray-900 rounded-lg shadow-2xl overflow-hidden">
                  {/* Modal Image */}
                  <img
                    src={selectedImage}
                    alt="Selected AI Generated"
                    className="w-full h-auto py-4 max-h-[80vh] object-contain rounded-lg"
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default PortfolioSection;
