import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const Whatwedo = () => {
  const { ref: textRef, inView: textVisible } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <section className="relative bg-black py-16 md:py-36 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-24">
        
        {/* Left Side Image */}
        <motion.div
          className="relative lg:w-1/3"
          whileHover={{ scale: 1.05, boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)' }}
          transition={{ duration: 0.3 }}
        >
          <img
            src="./images/generated-img/14.webp"
            alt="AI Creativity"
            className="rounded-lg shadow-lg md:-mt-80 object-cover transition-transform transform hover:scale-105"
          />
        </motion.div>

        {/* Middle Text Section */}
        <div className="lg:w-1/3 text-center lg:text-left space-y-6">
          <motion.h4
            className="text-blue-500 text-sm uppercase tracking-widest"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Bringing Ideas to Life
          </motion.h4>

          <motion.h1
            ref={textRef}
            className="text-4xl md:text-6xl font-extrabold text-white leading-tight"
            initial={{ opacity: 0, y: 50 }}
            animate={textVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.1 }}
          >
            Turn your <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 animate-gradient">
              imagination
            </span> 
            into stunning visuals with our 
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 animate-gradient">
              AI-powered tools
            </span>.
          </motion.h1>

          <motion.p
            className="text-gray-400 text-lg"
            initial={{ opacity: 0, y: 30 }}
            animate={textVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Our AI image generator empowers you to bring your creative visions to life in seconds. 
            From detailed illustrations to abstract art, our tools offer unparalleled control, 
            speed, and ease of use. Your creativity, powered by AI, becomes limitless.
          </motion.p>

          {/* Signature and Role */}
          <motion.div
            className="mt-8"
            initial={{ opacity: 0, y: 30 }}
            animate={textVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            <div className="flex flex-col items-center lg:items-start">
              <p className="text-xl font-semibold text-white">Alex Rivera</p>
              <p className="text-gray-400">Chief Innovation Officer</p>
            </div>
          </motion.div>
        </div>

        {/* Right Side Image with Circle Element */}
        <div className="relative lg:w-1/3 flex justify-center lg:justify-end">
          <motion.div
            className="relative"
            whileHover={{ scale: 1.05, boxShadow: '0 10px 20px rgba(0, 0, 0, 0.4)' }}
            transition={{ duration: 0.3 }}
          >
            <img
              src="./images/generated-img/15.webp"
              alt="Creative Visualization"
              className="rounded-lg shadow-lg md:mt-80 object-cover transition-transform transform hover:scale-105"
            />

            {/* Floating Circle Icon with Circular Text */}
            <motion.svg
              className="absolute -top-16 right-0 w-24 h-24"
              viewBox="0 0 100 100"
              animate={{ rotate: 360 }}
              transition={{ repeat: Infinity, duration: 6, ease: 'linear' }}
              whileHover={{ scale: 1.1 }}
            >
              <defs>
                <path
                  id="circlePath"
                  d="M50,50 m-45,0 a45,45 0 1,1 90,0 a45,45 0 1,1 -90,0"
                />
                <linearGradient id="circleGradient" gradientTransform="rotate(90)">
                  <stop offset="0%" stopColor="#3b82f6" />
                  <stop offset="100%" stopColor="#8b5cf6" />
                </linearGradient>
              </defs>
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="url(#circleGradient)"
                strokeWidth="10"
              />
              <text
                fill="white"
                fontSize="8"
                fontWeight="normal"
                textAnchor="middle"
              >
                <textPath href="#circlePath" startOffset="50%">
                  AI DESIGN • INNOVATE • CREATE •
                </textPath>
              </text>
            </motion.svg>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Whatwedo;
