import React from 'react';

const TestimonialSection = () => {
  // Dummy testimonials data
  const testimonials = [
    {
      name: 'Liam Martinez',
      role: 'Freelance Photographer',
      image: 'https://randomuser.me/api/portraits/women/19.jpg',
      rating: 5,
      quote:
        'This AI tool has transformed the way I edit photos. The enhancements are subtle yet powerful, allowing me to maintain my personal style while improving the overall quality of my work.',
    },
    {
      name: 'Olivia Brown',
      role: 'Marketing Manager',
      image: 'https://randomuser.me/api/portraits/women/12.jpg',
      rating: 4,
      quote:
        'I’ve used a lot of design tools, but this one stands out for its ability to quickly generate high-quality visuals. It has significantly reduced our project turnaround times.',
    },
    {
      name: 'Ethan Kim',
      role: 'Digital Artist',
      image: 'https://randomuser.me/api/portraits/men/19.jpg',
      rating: 5,
      quote:
        'Creating concept art with this AI tool has been a game-changer for me. The ability to bring my imagination to life so effortlessly is something I’ve never experienced before.',
    },
    {
      name: 'Ava Robinson',
      role: 'UI/UX Designer',
      image: 'https://randomuser.me/api/portraits/women/23.jpg',
      rating: 5,
      quote:
        'I use this platform to prototype designs and generate creative assets. It’s intuitive, efficient, and has saved me countless hours of manual work. A must-have tool for any designer.',
    },
    {
      name: 'Noah Johnson',
      role: 'Creative Director',
      image: 'https://randomuser.me/api/portraits/women/5.jpg',
      rating: 4,
      quote:
        'The AI image generator exceeded my expectations. It allows our team to explore new visual directions without being constrained by traditional design processes. Definitely recommend!',
    },
  ];
  

  return (
    <section className="bg-black pt-16 pb-0 md:py-16 md:pt-12 px-4">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row lg:items-start gap-12">
        {/* Left Side: Title */}
        <div className="lg:w-1/3 md:mt-28 text-left">
          <h4 className="text-blue-500 text-sm uppercase tracking-widest">
            Our Clients Say
          </h4>
          <h2 className="text-4xl md:text-5xl font-extrabold text-white mt-4">
            We <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">brought</span> their <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">ideas</span> to life
          </h2>
          <p className="text-gray-400 mt-2">10,000+ people love us</p>
        </div>

        {/* Right Side: Testimonials Cards */}
        <div className="lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`bg-gray-800 p-6 rounded-lg shadow-lg mb-8 transition-transform ${
                index % 2 === 0 ? 'transform md:translate-y-6' : 'transform translate-y-0'
              }`}
            >
              {/* Star Ratings */}
              <div className="flex mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-5 h-5 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 .587l3.668 7.431 8.2 1.193-5.916 5.758 1.396 8.131L12 18.896l-7.348 3.864 1.396-8.131L.132 9.211l8.2-1.193L12 .587z" />
                  </svg>
                ))}
              </div>

              {/* Quote */}
              <p className="text-gray-300 text-lg mb-6">“ {testimonial.quote} ”</p>

              {/* Client Info */}
              <div className="flex items-center">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <p className="text-white font-semibold">{testimonial.name}</p>
                  <p className="text-gray-400 text-sm">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
