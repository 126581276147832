import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaRobot } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Reusable Contact Info Item Component
const ContactInfoItem = ({ icon, title, details }) => (
  <motion.div
    className="flex items-start my-6"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex-shrink-0">
      <div className="w-12 h-12 flex items-center justify-center bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg">
        {icon}
      </div>
    </div>
    <div className="ml-4">
      <h4 className="text-lg font-medium text-gray-300">{title}</h4>
      {details.map((detail, index) => (
        <p key={index} className="mt-2 text-gray-400">
          {detail}
        </p>
      ))}
    </div>
  </motion.div>
);

// Animation Variants
const formVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const inputVariants = {
  focus: { scale: 1.02 },
  hover: { scale: 1.02 },
};

const buttonVariants = {
  hover: { scale: 1.05, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' },
  tap: { scale: 0.95 },
};

const ContactDetails = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const formRef = useRef(null);

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
    reset();
  };

  // Accessibility: Focus management
  useEffect(() => {
    if (formRef.current) {
      formRef.current.focus();
    }
  }, []);

  return (
    <section className="bg-gradient-to-b from-gray-900 to-black pt-16 pb-16 md:py-24">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          
          {/* Contact Information Section */}
          <motion.div
            className="text-white"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="inline-block bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold py-1 px-3 rounded-full text-sm mb-4 animate-pulse">
              Get in Touch
            </div>
            {/* Headline */}
            <h2 className="text-4xl font-extrabold text-white mb-6 leading-tight">
              Let's Create Stunning Images Together!
            </h2>
            <p className="text-lg text-gray-300 mb-8">
              Reach out to our AI Image Generator team for assistance or collaboration inquiries.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <ContactInfoItem
                icon={<FaMapMarkerAlt className="w-6 h-6" />}
                title="Office Location"
                details={['123 AI Boulevard', 'Imaginary City, Techland']}
              />
              <ContactInfoItem
                icon={<FaEnvelope className="w-6 h-6" />}
                title="Email Us"
                details={['support@imagegen.ai', 'sales@imagegen.ai']}
              />
              <ContactInfoItem
                icon={<FaPhoneAlt className="w-6 h-6" />}
                title="Call Us"
                details={['+1-800-IMAGE-AI', '+1-800-123-4567']}
              />
              <ContactInfoItem
                icon={<FaRobot className="w-6 h-6" />}
                title="AI Partnership"
                details={['ai-partners@imagegen.ai', 'collaborate@imagegen.ai']}
              />
            </div>                      
          </motion.div>

          {/* Contact Form Section */}
          <motion.div
            className="bg-gray-800 p-8 md:p-10 rounded-lg shadow-2xl"
            variants={formVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <div className="inline-block bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold py-1 px-3 rounded-full text-sm mb-4 animate-pulse">
              Send Us a Message
            </div>
            <h3 className="text-3xl font-semibold text-white mb-6">
              We're Here to Help
            </h3>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)} ref={formRef} aria-label="Contact Form">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Name Field */}
                <motion.div
                  variants={inputVariants}
                  whileHover="hover"
                  whileFocus="focus"
                  className="relative"
                >
                  <label htmlFor="name" className="block text-sm text-gray-300">
                    Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="name"
                    type="text"
                    {...register('name', { required: 'Name is required' })}
                    placeholder="Your Name"
                    className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500"
                    aria-invalid={errors.name ? 'true' : 'false'}
                  />
                  {errors.name && (
                    <span className="absolute bottom-0 left-0 text-red-500 text-sm">
                      {errors.name.message}
                    </span>
                  )}
                </motion.div>

                {/* Email Field */}
                <motion.div
                  variants={inputVariants}
                  whileHover="hover"
                  whileFocus="focus"
                  className="relative"
                >
                  <label htmlFor="email" className="block text-sm text-gray-300">
                    Email Address<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email"
                    type="email"
                    {...register('email', { required: 'Email is required' })}
                    placeholder="Your Email"
                    className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500"
                    aria-invalid={errors.email ? 'true' : 'false'}
                  />
                  {errors.email && (
                    <span className="absolute bottom-0 left-0 text-red-500 text-sm">
                      {errors.email.message}
                    </span>
                  )}
                </motion.div>
              </div>

              {/* Subject Field */}
              <motion.div
                variants={inputVariants}
                whileHover="hover"
                whileFocus="focus"
                className="relative"
              >
                <label htmlFor="subject" className="block text-sm text-gray-300">
                  Subject
                </label>
                <input
                  id="subject"
                  type="text"
                  {...register('subject')}
                  placeholder="Subject"
                  className="w-full px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500"
                />
              </motion.div>

              {/* Message Field */}
              <motion.div
                variants={inputVariants}
                whileHover="hover"
                whileFocus="focus"
                className="relative"
              >
                <label htmlFor="message" className="block text-sm text-gray-300">
                  Your Message<span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  {...register('message', { required: 'Message is required' })}
                  placeholder="Write your message"
                  className="w-full h-32 px-4 py-3 border border-gray-600 rounded-lg bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500 resize-none"
                  aria-invalid={errors.message ? 'true' : 'false'}
                ></textarea>
                {errors.message && (
                  <span className="absolute bottom-0 left-0 text-red-500 text-sm">
                    {errors.message.message}
                  </span>
                )}
              </motion.div>

              {/* Submit Button */}
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-3 rounded-full font-bold shadow-lg"
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
              >
                Send Message
              </motion.button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetails;
