import React, { useEffect } from 'react';
import "../../style/modal.css"
const Modelbox = () => { 

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://gradio.s3-us-west-2.amazonaws.com/4.40.0/gradio.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-black to-gray-800">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="py-16 md:py-24 relative">
          <div className="max-w-3xl mx-auto text-center z-20 relative">
            {/* Heading */}
            <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
              Transform Your Images with AI Power
            </h2>
            <p className="mt-4 text-lg text-gray-300">
              Upload any image and let AI enhance its quality by improving clarity, colors, and details in just a few seconds. Experience the future of image generation!
            </p>
            <p className="mt-4 text-sm text-red-300">  
              <span className="font-semibold">Note:</span> The model may take a few seconds to load. Please be patient.
            </p>
          </div> 

          <div className="relative w-full z-10 mt-12">
            <gradio-app
              src="https://rol-box-sddsdtest.hf.space"
            ></gradio-app>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Modelbox;
