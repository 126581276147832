  import React from 'react';

  const HeroSection = () => {
    return (
      <section className="relative bg-gray-950 py-12 md:py-0 min-h-screen flex items-center overflow-hidden">
        {/* Background - Particle or Dotted Abstract */}
        <div className="absolute inset-0 overflow-hidden">
          {/* <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-purple-900 opacity-5"></div>  */}
          {/* Abstract dotted or line effects for a tech look */} 
          <img src="/images/bg-33.jpg" alt="Dots" className="absolute top-0 left-0 w-full h-full opacity-70 z-10 bg-gradient-to-t from-indigo-950 " />
          <div className="absolute top-0 left-0 w-full h-full z-0">
            <svg
              className="absolute left-10 top-20 opacity-30"
              width="500"
              height="500"
              viewBox="0 0 500 500"
            >
              <circle cx="250" cy="250" r="200" fill="url(#grad)" />
              <defs>
                <radialGradient id="grad">
                  <stop offset="0%" stopColor="cyan" />
                  <stop offset="100%" stopColor="purple" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>

        {/* Main Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {/* Left Side Text Section */}
            <div className="text-start lg:w-1/2">
              <h1 className="text-5xl md:text-7xl font-extrabold text-white leading-tight">
                Generate <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">high-quality</span> images with AI
              </h1>
              <p className="mt-6 text-lg md:text-xl text-gray-100 max-w-md">
                Turn your ideas into stunning visuals instantly with our cutting-edge AI-powered image generator. Get started now and see the magic happen in seconds.
              </p>

              {/* Call to Action Button */}
              <div className="mt-10 text-center md:text-start">
                <a
                  href="/model-box"
                  className="inline-block px-10 py-2 md:py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white text-lg font-semibold rounded-full shadow-lg transition-transform transform hover:scale-110 hover:shadow-2xl"
                >
                  Get Started Image
                </a>
              </div>
            </div>

            {/* Right Side Dynamic Image Gallery */}
            <div className="mt-12 lg:mt-0 lg:w-1/2 flex flex-wrap justify-center relative">
              {/* Diagonally Placed Images */}
              <div className="relative w-48 h-48 transform rotate-3 m-2 shadow-lg">
                <img
                  src="./images/generated-img/11.webp"
                  alt="AI Generated Image 1"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="relative w-48 h-48 transform -rotate-6 m-2 shadow-lg">
                <img
                  src="./images/generated-img/6.webp"
                  alt="AI Generated Image 2"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="relative w-48 h-48 transform rotate-6 m-2 shadow-lg">
                <img
                  src="./images/generated-img/3.webp"
                  alt="AI Generated Image 3"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="relative w-48 h-48 transform -rotate-3 m-2 shadow-lg">
                <img
                  src="./images/generated-img/13.webp"
                  alt="AI Generated Image 4"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  export default HeroSection;
