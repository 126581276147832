import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FaEye, FaEyeSlash, FaGoogle } from 'react-icons/fa';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import { useAuth } from '../../../Common/AuthProvider';
import { motion } from 'framer-motion';

const SigninPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { login, isAuthenticated } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const formRef = useRef(null);

    const characterLimits = {
        email: 100,
        password: 20,
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1); // Redirect to the previous page if already logged in
        }
    }, [isAuthenticated, navigate]);

    // Validation Logic
    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required.';
        } else if (email.length > characterLimits.email) {
            errors.email = `Email must be less than ${characterLimits.email} characters.`;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length > characterLimits.password) {
            errors.password = `Password must be less than ${characterLimits.password} characters.`;
        }
        return errors;
    };

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
            const user = storedUsers.find((u) => u.email === email && u.password === password);

            if (user) {
                const token = Math.random().toString(36).substr(2); // Generate random token
                localStorage.setItem('authToken', token);
                localStorage.setItem('currentUser', JSON.stringify(user));
                login(token); // Call login function from AuthProvider
                navigate('/model-box'); // Redirect to homepage after successful login
            } else {
                setErrors({ general: 'Invalid email or password.' });
            }
        }
    };

    const handleGoogleSuccess = (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const googleUser = {
            email: decoded.email,
            full_name: decoded.name,
        };

        const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
        if (!existingUsers.find((u) => u.email === googleUser.email)) {
            existingUsers.push({ email: googleUser.email, password: '', full_name: googleUser.full_name });
            localStorage.setItem('users', JSON.stringify(existingUsers));
        }

        const authToken = Math.random().toString(36).substr(2); // Generate random token
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('currentUser', JSON.stringify(googleUser));
        login(authToken); // Call login function from AuthProvider
        navigate('/model-box'); // Redirect to homepage
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    // Animation Variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.6 } },
    };

    const formVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.6 } },
    };

    const inputVariants = {
        focus: { scale: 1.02 },
        hover: { scale: 1.02 },
    };

    const buttonVariants = {
        hover: { scale: 1.05, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' },
        tap: { scale: 0.95 },
    };

    return (
        <motion.div
            className="min-h-screen flex items-center justify-center bg-gradient-to-br from-black via-gray-900 to-gray-800 py-12 md:py-24"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="grid mx-4 md:mx-0 grid-cols-1 md:grid-cols-2 max-w-5xl w-full bg-gray-800 rounded-2xl shadow-2xl overflow-hidden gap-6">
                
                {/* Left Side - Illustration */}
                <motion.div
                    className="hidden md:flex justify-center items-center bg-gradient-to-br from-blue-700 to-purple-700 p-4"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <img
                        src="/images/generated-img/11.webp"
                        alt="AI Image Enhancement Illustration"
                        className="w-full h-full object-cover rounded-l-2xl"
                    />
                </motion.div>

                {/* Right Side - Login Form */}
                <motion.div
                    className="w-full p-10"
                    variants={formVariants}
                >
                    <div className="text-center mb-8">
                        <h2 className="text-3xl font-extrabold text-white">Log in to ImageGen.AI</h2>
                        <p className="text-gray-400 mt-2">
                            Or{' '}
                            <Link to="/signup" className="text-blue-400 hover:underline">
                                create a new account
                            </Link>
                        </p>
                    </div>

                    <form className="space-y-6" onSubmit={handleSubmit} ref={formRef} aria-label="Sign in form">
                        {/* Email Field */}
                        <motion.div
                            variants={inputVariants}
                            whileHover="hover"
                            whileFocus="focus"
                            className="relative"
                        >
                            <label htmlFor="email" className="block text-sm font-medium text-gray-400">
                                Email<span className="text-red-500">*</span>
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    errors.email ? 'border-red-500' : 'border-gray-600'
                                }`}
                                maxLength={characterLimits.email}
                                aria-invalid={errors.email ? 'true' : 'false'}
                                aria-describedby="email-error"
                            />
                            {errors.email && (
                                <p id="email-error" className="text-red-500 text-xs mt-2">
                                    {errors.email}
                                </p>
                            )}
                        </motion.div>

                        {/* Password Field */}
                        <motion.div
                            variants={inputVariants}
                            whileHover="hover"
                            whileFocus="focus"
                            className="relative"
                        >
                            <label htmlFor="password" className="block text-sm font-medium text-gray-400">
                                Password<span className="text-red-500">*</span>
                            </label>
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    errors.password ? 'border-red-500' : 'border-gray-600'
                                }`}
                                maxLength={characterLimits.password}
                                aria-invalid={errors.password ? 'true' : 'false'}
                                aria-describedby="password-error"
                            />
                            <span
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center cursor-pointer"
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setShowPassword(!showPassword);
                                    }
                                }}
                            >
                                {showPassword ? <FaEyeSlash className="text-gray-400" /> : <FaEye className="text-gray-400" />}
                            </span>
                            {errors.password && (
                                <p id="password-error" className="text-red-500 text-xs mt-2">
                                    {errors.password}
                                </p>
                            )}
                        </motion.div>

                        {/* Remember Me */}
                        <motion.div
                            variants={inputVariants}
                            whileHover="hover"
                            whileFocus="focus"
                            className="flex items-center justify-between"
                        >
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-blue-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-400">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <Link to="/forgot-password" className="text-sm text-blue-400 hover:underline">
                                    Forgot your password?
                                </Link>
                            </div>
                        </motion.div>

                        {/* Submit Button */}
                        <motion.button
                            type="submit"
                            className="w-full py-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            Log in
                        </motion.button>
                    </form>

                    {/* General Error */}
                    {errors.general && <p className="text-red-500 mt-4 text-sm text-center">{errors.general}</p>}

                    {/* Divider */}
                    <div className="flex items-center my-6">
                        <hr className="flex-grow border-gray-600" />
                        <span className="mx-2 text-gray-400">OR</span>
                        <hr className="flex-grow border-gray-600" />
                    </div>

                    {/* Google Login */}
                    <div className="w-full flex justify-center">
                        <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                onFailure={handleGoogleFailure}
                                useOneTap
                                render={(renderProps) => (
                                    <motion.button
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        className="w-full flex items-center justify-center py-3 px-4 border rounded-lg bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
                                        variants={buttonVariants}
                                        whileHover="hover"
                                        whileTap="tap"
                                        aria-label="Sign in with Google"
                                    >
                                        <FaGoogle className="w-6 h-6 mr-2 text-red-500" />
                                        <span className="text-gray-700">Sign in with Google</span>
                                    </motion.button>
                                )}
                            />
                        </GoogleOAuthProvider>
                    </div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default SigninPage;
