import React, { useEffect, useState, useRef } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Common/AuthProvider';
import { FaEye, FaEyeSlash, FaGoogle } from 'react-icons/fa';
import { motion } from 'framer-motion';

const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();
    const formRef = useRef(null);

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1); // Redirect to the previous page if already logged in
        }
    }, [isAuthenticated, navigate]);

    // Validation Logic with Real-Time Feedback
    const validate = () => {
        const errors = {};
        if (!fullName.trim()) {
            errors.fullName = 'Full name is required.';
        } else if (fullName.length > 100) {
            errors.fullName = 'Full name must be less than 100 characters.';
        }
        if (!email.trim()) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 100) {
            errors.email = 'Email must be less than 100 characters.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > 100) {
            errors.password = 'Password must be less than 100 characters.';
        }
        return errors;
    };

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            // Simulate API call
            setTimeout(() => {
                const storedUsers = JSON.parse(localStorage.getItem('users')) || [];
                const existingUser = storedUsers.find((user) => user.email === email);

                if (existingUser) {
                    setErrors({ general: 'A user with this email already exists.' });
                    setIsSubmitting(false);
                } else {
                    const newUser = { email, password, full_name: fullName };
                    storedUsers.push(newUser);
                    localStorage.setItem('users', JSON.stringify(storedUsers));
                    setIsSubmitting(false);
                    setIsSuccess(true);
                    // Redirect to signin after a short delay
                    setTimeout(() => {
                        navigate('/signin');
                    }, 2000);
                }
            }, 1500);
        }
    };

    const handleGoogleSuccess = (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const googleUser = {
            email: decoded.email,
            full_name: decoded.name,
        };

        const existingUsers = JSON.parse(localStorage.getItem('users')) || [];
        if (!existingUsers.find(u => u.email === googleUser.email)) {
            existingUsers.push({ email: googleUser.email, password: '', full_name: googleUser.full_name });
            localStorage.setItem('users', JSON.stringify(existingUsers));
        }

        const authToken = Math.random().toString(36).substr(2);  
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('currentUser', JSON.stringify(googleUser));
        login(authToken);
        navigate('/model-box');
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Signup Failed:', error);
        setErrors({ general: 'Google signup failed. Please try again.' });
    };

    // Animation Variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.6 } },
    };

    const formVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.6 } },
    };

    const inputVariants = {
        focus: { scale: 1.02 },
        hover: { scale: 1.02 },
    };

    const buttonVariants = {
        hover: { scale: 1.05, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' },
        tap: { scale: 0.95 },
    };

    return (
        <motion.div
            className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-black to-gray-800 py-12 md:py-24"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="grid  mx-4 md:mx-0 grid-cols-1 md:grid-cols-2 max-w-5xl w-full  bg-gray-800 rounded-2xl shadow-2xl overflow-hidden gap-6">
                
                {/* Left Side - Illustration */}
                <motion.div
                    className="hidden md:flex justify-center items-center bg-gradient-to-br from-blue-700 to-purple-700 p-4"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <img
                        src="/images/generated-img/24.webp"
                        alt="AI Image Enhancement Illustration"
                        className="w-full h-full object-cover rounded-l-2xl"
                    />
                </motion.div>

                {/* Right Side - Signup Form */}
                <motion.div
                    className="w-full p-10"
                    variants={formVariants}
                >
                    <div className="text-center mb-8">
                        <h2 className="text-3xl font-extrabold text-white">Sign up for ImageGen.AI</h2>
                        <p className="text-gray-400 mt-2">
                            Or{' '}
                            <Link to="/signin" className="text-blue-400 hover:underline">
                                log in to your account
                            </Link>
                        </p>
                    </div>

                    {isSuccess ? (
                        <motion.div
                            className="text-center text-green-400"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.6 }}
                        >
                            <p className="text-lg font-medium">Signup successful! Redirecting to sign in...</p>
                        </motion.div>
                    ) : (
                        <form className="space-y-6" onSubmit={handleSubmit} ref={formRef} aria-label="Sign up form">
                            {/* Full Name Field */}
                            <motion.div
                                variants={inputVariants}
                                whileHover="hover"
                                whileFocus="focus"
                                className="relative"
                            >
                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-400">
                                    Full Name<span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="fullName"
                                    name="fullName"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                        errors.fullName ? 'border-red-500' : 'border-gray-600'
                                    }`}
                                    maxLength={100}
                                    aria-invalid={errors.fullName ? 'true' : 'false'}
                                    aria-describedby="fullName-error"
                                />
                                {errors.fullName && (
                                    <p id="fullName-error" className="text-red-500 text-xs mt-2">
                                        {errors.fullName}
                                    </p>
                                )}
                            </motion.div>

                            {/* Email Field */}
                            <motion.div
                                variants={inputVariants}
                                whileHover="hover"
                                whileFocus="focus"
                                className="relative"
                            >
                                <label htmlFor="email" className="block text-sm font-medium text-gray-400">
                                    Email<span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                        errors.email ? 'border-red-500' : 'border-gray-600'
                                    }`}
                                    maxLength={100}
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    aria-describedby="email-error"
                                />
                                {errors.email && (
                                    <p id="email-error" className="text-red-500 text-xs mt-2">
                                        {errors.email}
                                    </p>
                                )}
                            </motion.div>

                            {/* Password Field */}
                            <motion.div
                                variants={inputVariants}
                                whileHover="hover"
                                whileFocus="focus"
                                className="relative"
                            >
                                <label htmlFor="password" className="block text-sm font-medium text-gray-400">
                                    Password<span className="text-red-500">*</span>
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={`block w-full px-5 py-3 mt-2 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                        errors.password ? 'border-red-500' : 'border-gray-600'
                                    }`}
                                    maxLength={100}
                                    aria-invalid={errors.password ? 'true' : 'false'}
                                    aria-describedby="password-error"
                                />
                                <span
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center cursor-pointer"
                                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            setShowPassword(!showPassword);
                                        }
                                    }}
                                >
                                    {showPassword ? <FaEyeSlash className="text-gray-400" /> : <FaEye className="text-gray-400" />}
                                </span>
                                {errors.password && (
                                    <p id="password-error" className="text-red-500 text-xs mt-2">
                                        {errors.password}
                                    </p>
                                )}
                            </motion.div>

                            {/* Submit Button */}
                            <motion.div
                                variants={inputVariants}
                                whileHover="hover"
                                whileFocus="focus"
                            >
                                <button
                                    type="submit"
                                    className="w-full py-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105 flex items-center justify-center"
                                    variants={buttonVariants}
                                    whileHover="hover"
                                    whileTap="tap"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <svg className="w-6 h-6 mr-3 text-white animate-spin" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V2.83a1 1 0 011.7-.71l5 5a1 1 0 010 1.41l-5 5a1 1 0 01-1.41 0a1 1 0 01-.29-.71V8a6 6 0 10-2 4.58"   
                                            />
                                        </svg>
                                    ) : (
                                        'Sign Up'
                                    )}
                                </button>
                            </motion.div>
                        </form>
                    )}

                    {!isSuccess && (
                        <>
                            {/* Divider */}
                            <div className="flex items-center my-6">
                                <hr className="flex-grow border-gray-600" />
                                <span className="mx-2 text-gray-400">OR</span>
                                <hr className="flex-grow border-gray-600" />
                            </div>

                            {/* Google Signup */}
                            <div className="w-full flex justify-center">
                                <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onFailure={handleGoogleFailure}
                                        useOneTap
                                        render={(renderProps) => (
                                            <motion.button
                                                onClick={renderProps.onClick}
                                                disabled={renderProps.disabled}
                                                className="w-full flex items-center justify-center py-3 px-4 border rounded-lg bg-white shadow-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
                                                variants={buttonVariants}
                                                whileHover="hover"
                                                whileTap="tap"
                                                aria-label="Sign up with Google"
                                            >
                                                <FaGoogle className="w-6 h-6 mr-2 text-red-500" />
                                                <span className="text-gray-700">Sign up with Google</span>
                                            </motion.button>
                                        )}
                                    />
                                </GoogleOAuthProvider>
                            </div>

                            {/* General Error */}
                            {errors.general && (
                                <p className="text-red-500 mt-4 text-sm text-center">
                                    {errors.general}
                                </p>
                            )}
                        </>
                    )}
                </motion.div>
            </div>
        </motion.div>
        );
    };

    export default SignupPage;
