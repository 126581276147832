import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaPinterest, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="relative pt-24 bg-black text-white">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img src="/images/bg-33.jpg" alt="Background" className="w-full h-full object-cover opacity-30" />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 grid grid-cols-1 md:grid-cols-3 gap-8 z-10">
        {/* Logo and About Section */}
        <div>
          <h3 className="text-3xl font-bold mb-4 text-white">
            ImageGenAI
          </h3>
          <p className="text-gray-300 mb-6 leading-relaxed">
            ImageGenAI transforms your ideas into stunning visuals using state-of-the-art AI technology.
            From concept art to realistic renderings, we make creativity accessible for everyone.
          </p>
          <div className="flex space-x-4 mt-4">
            <a href="#" aria-label="Facebook" className="text-gray-400 hover:text-blue-500 transition-colors duration-300">
              <FaFacebookF size={20} />
            </a>
            <a href="#" aria-label="Twitter" className="text-gray-400 hover:text-blue-400 transition-colors duration-300">
              <FaTwitter size={20} />
            </a>
            <a href="#" aria-label="Instagram" className="text-gray-400 hover:text-pink-500 transition-colors duration-300">
              <FaInstagram size={20} />
            </a>
            <a href="#" aria-label="Pinterest" className="text-gray-400 hover:text-red-500 transition-colors duration-300">
              <FaPinterest size={20} />
            </a>
          </div>
        </div>

        {/* Contact Information */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white">
            Contact Us
          </h4>
          <p className="text-gray-300 mb-2">
            1234 AI Drive, Creativity City, Imagination Land, 56789
          </p>
          <p className="text-gray-300 mb-4">
            Need help or have a question? Reach out to us:
          </p>
          <div className="flex items-center text-gray-300 mb-2">
            <FaPhoneAlt className="mr-2 text-blue-500" />
            <a href="tel:0123456789" className="hover:text-white transition-colors duration-300">
              0123-456-789
            </a>
          </div>
          <div className="flex items-center text-gray-300">
            <FaEnvelope className="mr-2 text-blue-500" />
            <a href="mailto:support@imagegenai.com" className="hover:text-white transition-colors duration-300">
              support@imagegenai.com
            </a>
          </div>
        </div>

        {/* Resources and Tutorials Section */}
        <div>
          <h4 className="text-xl font-semibold mb-4 text-white">
            Resources & Tutorials
          </h4>
          <p className="text-gray-300 mb-6">
            Explore how to get the most out of ImageGenAI with our tutorials and guides.
          </p>
          <ul className="space-y-2">
            <li>
              <a href="#" className="text-gray-300 hover:text-white transition-colors duration-300">
                AI Image Generator Guide
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-300 hover:text-white transition-colors duration-300">
                Best Practices for Creating Realistic AI Images
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-300 hover:text-white transition-colors duration-300">
                How to Integrate AI-Generated Content into Photoshop
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-300 hover:text-white transition-colors duration-300">
                Customizing Your AI Art Style
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Newsletter Signup */}
      {/* <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 z-10">
        <div className="bg-gray-800 bg-opacity-75 p-6 rounded-md">
          <h4 className="text-2xl font-semibold mb-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            Stay Updated
          </h4>
          <p className="text-gray-300 text-center mb-6">
            Subscribe to our newsletter for the latest updates and tutorials.
          </p>
          <form className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full sm:w-auto px-4 py-2 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="px-6 py-2 rounded-md bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold hover:opacity-90 transition-opacity duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div> */}

      {/* Copyright Section */}
      <div className="relative py-8 pt-6 text-center text-gray-400 z-10">
        <p>
          © {new Date().getFullYear()}{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">
            ImageGenAI
          </span>
          . All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
