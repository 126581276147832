import React from 'react';
import PortfolioSection from './portfolio/PortfolioSection';

const Portfolio = () => {
  return (
    <> 
      <PortfolioSection />
    </>
  );
};

export default Portfolio;
