import React from 'react';
import { FaHeart, FaMobileAlt, FaMagic, FaBolt } from 'react-icons/fa';
import { motion } from 'framer-motion'; // For advanced animations

const features = [
  {
    icon: <FaHeart />,
    title: 'Free to Try',
    description:
      'Use our AI Image Generator for free with no initial cost. Test it out and see the quality of AI-generated visuals.',
    color: 'text-blue-400',
  },
  {
    icon: <FaMagic />,
    title: 'Quick & Easy',
    description:
      'Generate stunning images in seconds. Just describe what you need, and let our AI do the magic!',
    color: 'text-purple-400',
  },
  {
    icon: <FaMobileAlt />,
    title: 'Fun & Creative',
    description:
      'Experiment with different styles, filters, and prompts. Create multiple versions of your visuals with ease.',
    color: 'text-green-400',
  },
  {
    icon: <FaBolt />,
    title: 'Compatible Everywhere',
    description:
      'Use our platform across all your devices – mobile, tablet, or desktop. Seamlessly create on the go.',
    color: 'text-yellow-400',
  },
];

const AboutFeatures = () => {
  return (
    <section className="bg-black py-8 md:py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center mb-16">
        <motion.h2
          className="text-4xl font-extrabold text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Why Choose Our AI Image Generator?
        </motion.h2>
        <motion.p
          className="text-gray-400 mt-4 max-w-2xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.6 }}
        >
          Experience the power of AI-driven creativity. Here’s why our platform is perfect for you.
        </motion.p>
      </div>

      {/* Grid Layout for Feature Cards */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-gray-800 p-8 rounded-lg shadow-lg text-center"
            whileHover={{ scale: 1.05, boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)' }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <div className={`flex items-center justify-center h-16 w-16 mx-auto mb-4 ${feature.color} bg-gray-700 rounded-full`}>
              <span className="text-2xl">{feature.icon}</span>
            </div>
            <h3 className="text-xl font-semibold text-white mb-3">{feature.title}</h3>
            <p className="text-gray-400">
              {feature.description}
            </p>
            {/* Optional Tooltip */}
            {/* <Tooltip content={feature.description}>
              <span className="mt-4 inline-block text-sm text-blue-400 underline cursor-pointer">Learn More</span>
            </Tooltip> */}
          </motion.div>
        ))}
      </div>

      {/* Call to Action Button */}
      <motion.div
        className="text-center mt-16"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5, duration: 0.6 }}
      >
        <a
          href="/model-box"
          className="inline-block px-10 py-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white text-lg font-semibold rounded-full shadow-lg hover:scale-105 transform transition duration-300"
        >
          Try Now
        </a>
      </motion.div>
    </section>
  );
};

export default AboutFeatures;
