import React from 'react';
import AboutUsSection from './About/AboutUsSection';
import TestimonialSection from './Home/Testimonialsection';
import AboutFeatures from './About/AboutFeatures';

const About = () => {
  return (
    <> 
      <AboutUsSection />
      <AboutFeatures />
      <TestimonialSection /> 
    </>
  );
};

export default About;
