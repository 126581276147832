import React from 'react';

const AboutUsSection = () => {
  return (
    <section className="bg-black py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        
        {/* Left Side Image */}
        <div className="relative bg-gradient-to-b from-gray-900 via-blue-900 to-purple-900 p-8 md:p-16 rounded-xl">
          <img
            src="./images/generated-img/8.webp"
            alt="AI Generated Artwork"
            className="w-full h-auto rounded-lg shadow-2xl transform hover:scale-105 transition duration-300"
          />
        </div>

        {/* Right Side Text Content */}
        <div className="text-white">
          <h2 className="text-5xl font-extrabold mb-6 text-white">
            Discover the Power of <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 animate-gradient">AI Image Generation</span>
          </h2>
          <p className="text-gray-400 mb-6 leading-relaxed">
            Create breathtaking images with the help of our AI-powered image generator. Turn simple text into vibrant, high-quality visuals in just a few seconds. From art and portraits to commercial products and creative concepts, the possibilities are endless.
          </p>
          <p className="text-gray-400 mb-6 leading-relaxed">
            Whether you need visuals for your next marketing campaign, website design, or social media, our AI image generator is here to help. All it takes is a simple description, and our AI turns your words into stunning visuals that bring your imagination to life.
          </p>
          {/* Call to Action Button */}
          <a
            href="/model-box"
            className="inline-block px-8 py-2 bg-gradient-to-r from-blue-400 to-purple-500 text-white text-lg font-semibold rounded-full shadow-lg hover:from-blue-500 hover:to-purple-600 transform transition duration-300"
          >
            Generate Your AI Image Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
