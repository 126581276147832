import React, { useState } from 'react';
  import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/AuthProvider';

const Header = () => {
  // State to manage mobile menu
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'text-white' : 'text-gray-400';
  };

  // Function to handle menu close on link click
  const handleMenuClick = () => {
    setIsOpen(false); // Closes the mobile menu
  };

  return (
    <>
      {/* Top Announcement Bar */}
      {/* <div className="bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center py-2">
            <div className="flex space-x-6 mx-auto items-center">
              <span className="text-white font-semibold text-sm md:text-base">
                Transform Ordinary Photos into <span className="text-yellow-300">Extraordinary Images</span>
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* Header Navigation */}
      <header className="bg-gray-950">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-3">
              <img src="/images/logo.png" className="h-8 md:h-12" alt="Logo" />
              <span className="self-center text-2xl font-extrabold text-white whitespace-nowrap">
              ImageGen AI 
              </span>
            </Link>

            {/* Nav Links for larger screens */}
            <nav className="hidden md:flex space-x-6 items-center">
              <Link to="/" className={`inline-block font-semibold px-2 py-1 text-lg ${activeLink('/')} hover:text-white transition`}>
                Home
              </Link>
              <Link to="/about" className={`inline-block font-semibold px-2 py-1 text-lg ${activeLink('/about')} hover:text-white transition`}>
                About
              </Link>
              <Link to="/portfolio" className={`inline-block font-semibold px-2 py-1 text-lg ${activeLink('/portfolio')} hover:text-white transition`}>
                Generated Images
              </Link>
              <Link to="/contact" className={`inline-block font-semibold px-2 py-1 text-lg ${activeLink('/contact')} hover:text-white transition`}>
                Contact
              </Link>
            </nav>

            {/* Buttons for larger screens */}
            {!isAuthenticated ? (
              <div className="hidden md:flex items-center space-x-4">
                <Link to="/signin" className={`inline-block font-semibold text-lg text-gray-400 hover:text-white transition`}>
                  Sign in
                </Link>
                <Link
                  to="/signup"
                  className="bg-gradient-to-r from-purple-600 to-blue-500 text-white px-5 py-2 rounded-full font-semibold transition-transform transform hover:scale-105"
                >
                  Join AI
                </Link>
              </div>
            ) : (
              <div className="hidden md:flex items-center space-x-4">
                <Link
                  to="/model-box"
                  className="bg-gradient-to-r from-purple-600 to-blue-500 text-white px-5 py-2 rounded-full font-semibold transition-transform transform hover:scale-105"
                >
                  AI Model
                </Link>
                <button
                  onClick={() => logout()}
                  className="inline-block font-semibold text-lg text-gray-400 hover:text-white transition"
                >
                  Logout
                </button>
              </div>
            )}

            {/* Hamburger Menu for mobile */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-white hover:text-gray-400 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <nav className="md:hidden px-2 pt-2 pb-4 space-y-1">
            <Link
              to="/"
              className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
              onClick={handleMenuClick}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
              onClick={handleMenuClick}
            >
              About
            </Link>
            <Link
              to="/portfolio"
              className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
              onClick={handleMenuClick}
            >
              Use Cases
            </Link>
            <Link
              to="/contact"
              className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
              onClick={handleMenuClick}
            >
              Contact
            </Link>
            {!isAuthenticated ? (
              <>
                <Link
                  to="/signin"
                  className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
                  onClick={handleMenuClick}
                >
                  Sign in
                </Link>
                <Link
                  to="/signup"
                  className="block max-w-32 mt-4 bg-gradient-to-r from-purple-600 to-blue-500 text-white px-4 py-2 rounded-full font-semibold transition-transform transform hover:scale-105"
                  onClick={handleMenuClick}
                >
                  Join AI
                </Link>
              </>
            ) : (
              <>
                <Link
                  onClick={() => { handleMenuClick(); logout(); }}
                  className="block text-gray-400 hover:text-white px-3 py-2 rounded-md transition"
                >
                  Logout
                </Link>
                <Link
                  to="/model-box"
                  className="block max-w-32 mt-4 bg-gradient-to-r from-purple-600 to-blue-500 text-white px-4 py-2 rounded-full font-semibold transition-transform transform hover:scale-105"
                  onClick={handleMenuClick}
                >
                  AI Model
                </Link>
              </>
            )}
          </nav>
        )}
      </header>
    </>
  );
};

export default Header;
